.applications-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.applications-section {
  display: flex;
  flex-direction: column;
}

.top-section {
  flex-grow: 1;
  min-height: 0;
  overflow: hidden;
}

.bottom-section {
  flex-shrink: 0;
  margin-top: 3rem;
  height: 340px;
}

@media screen and (max-height: 950px) {
  .bottom-section {
    max-height: 300px;
    margin-top: 2rem;
  }
}

@media screen and (max-height: 800px) {
  .bottom-section {
    max-height: 275px;
    margin-top: 2rem;
  }
}

.section-header {
  font-size: 18px;
  font-weight: 500;
  color: theme('colors.dark-green');
  margin-bottom: 1rem;
}

.control-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1.5rem;
}

.expand-icon-cell {
  text-align: right;
}

.expand-icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  display: inline-block;
}

@media (max-width: 1000px) {
  .expand-icon {
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
  }
}

@media (max-width: 900px) {
  .expand-icon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  background-color: rgba(240, 240, 240, 0.5);
}

.toggle-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.toggle-button {
  flex: 1;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: #f0f0f0;
  border: none;
  border-radius: 0.25rem;
  transition: background-color 0.2s ease;
}

.toggle-button.active {
  background-color: #00684a;
  color: white;
}

.toggle-button:not(.active):hover {
  background-color: #e0e0e0;
}
