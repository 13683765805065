.page-icon {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.filled-button {
  color: white;
  background-color: #00684a;
  font-weight: 600;
}

.outlined-button {
  color: #00684a;
  background-color: white;
  border: 1px solid #00684a;
  font-weight: 600;
}

.line-divider {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
