.sidebar-link {
  padding: 0.4rem;
  font-size: 0.9375rem;
  cursor: pointer;
  border-radius: 0.25rem;
}

.sidebar-link-active {
  color: theme('colors.fortify-green');
  font-weight: 600;
  background-color: #f0f0f0;
}

.sidebar-link-inactive {
  color: theme('colors.dark-green');
  font-weight: 400;
}

.user-section {
  border-top: none;
  margin: 0rem 1.25rem 1.75rem;
}

.user-section-content {
  margin-top: 0.75rem;
  padding-top: 1.25rem;
  display: flex;
  align-items: center;
  border-top: 0.6px solid rgba(75, 82, 106, 0.13);
}

.user-profile-image {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-right: 0.75rem;
}

.sidebar-collapsed .user-profile-image {
  width: 28px;
  height: 28px;
  margin-right: 0;
}

.user-info {
  flex-grow: 1;
}

.user-name {
  font-size: 0.875rem;
  font-weight: 500;
  color: theme('colors.dark-green');
}

.user-email {
  font-size: 0.75rem;
  color: #68867d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 22ch;
}

.user-section-dots {
  width: 26px;
  height: 26px;
  cursor: pointer;
}

.school-selector {
  width: 100%;
  position: relative;
}

.school-selector-content {
  display: flex;
  align-items: center;
  padding: 0.6rem;
  border-radius: 0.25rem;
}

.school-selector-content.hoverable:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.school-logo {
  min-width: 34px;
  width: 34px;
  height: 34px;
  border-radius: 4px;
}

.school-name {
  flex-grow: 1;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  color: theme('colors.dark-green');
}

.school-selector-arrow {
  width: 24px;
  height: 24px;
  margin-left: 0.5rem;
}

.school-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #e1e7e5;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 50;
  padding: 0.5rem;
  margin-top: 0.25rem;
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.school-dropdown::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;  /* Safari and Chrome */
}

.school-dropdown-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 13px;
  border-radius: 0.25rem;
  margin-bottom: 0.25rem;
}

.school-dropdown-item:last-child {
  margin-bottom: 0;
}

.school-dropdown-item:hover {
  background-color: #f0f0f0;
}

.school-logo-placeholder {
  min-width: 34px;
  width: 34px;
  height: 34px;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-right: 0.75rem;
}

.hidden {
  display: none;
}

.collapse-section {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0.5rem 0rem;
}

.sidebar-collapsed .collapse-section {
  justify-content: center;
}

.flex-grow {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.flex-grow::-webkit-scrollbar {
  display: none;
}

.flex-grow:hover:not(.sidebar-collapsed .flex-grow) {
  -ms-overflow-style: auto;
  scrollbar-width: auto;
}

.flex-grow:hover:not(.sidebar-collapsed .flex-grow)::-webkit-scrollbar {
  display: block;
  width: 8px;
}

.flex-grow:hover:not(.sidebar-collapsed .flex-grow)::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.collapse-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem;
  cursor: pointer;
  border: none;
  background: none;
}

.collapse-button:hover {
  opacity: 0.8;
}

.user-section-modal {
  position: absolute;
  top: 0.5rem;
  left: 0;
  width: 12rem;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  z-index: 50;
}