.notification-settings-container {
  max-width: 800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 2rem;
}

.notification-settings-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: min-content;
}

.notification-settings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;
  width: 100%;
}

.notification-option {
  border: 1px solid #e1e7e5;
  border-radius: 0.5rem;
  padding: 1.25rem;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  height: 100%;
}

.notification-option.selected {
  background-color: rgba(0, 104, 74, 0.05);
  border-color: #00684a;
}

.notification-option:hover {
  border-color: #00684a;
}

.notification-header {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  width: 100%;
}

.notification-checkbox {
  margin-top: 0.25rem;
  flex-shrink: 0;
}

.notification-content {
  flex: 1;
  min-width: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.notification-label {
  font-weight: 500;
  color: theme('colors.dark-green');
  margin-bottom: 0.25rem;
}

.notification-description {
  font-size: 0.875rem;
  color: theme('colors.secondary-green');
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.save-button {
  background-color: theme('colors.fortify-green');
  color: white;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  min-width: 130px;
  height: 44px;
  align-self: flex-end;
}

.save-button:hover {
  background-color: theme('colors.hover-green');
}

.save-button:disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
}

@media (max-width: 640px) {
  .notification-settings-grid {
    grid-template-columns: 1fr;
  }
  
  .notification-settings-container {
    padding: 1rem;
  }
}