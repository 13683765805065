.member-settings-container {
    width: 100%;
    max-width: 4xl;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }
  
  .member-settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .member-table-container {
    flex-grow: 1;
    overflow-y: auto;
    border: 1px solid #e5e7eb;
    border-radius: 0 0 0.5rem 0.5rem;
  }
  
  .member-table {
    width: 100%;
    background-color: white;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .member-table td {
    padding: 1rem 1.5rem;
    vertical-align: middle;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .member-info-cell {
    display: flex;
    align-items: center;
    padding-right: 2rem;
    min-width: 300px;
  }
  
  .member-role-cell {
    padding-right: 2rem;
    min-width: 100px;
    color: #6B7280;
  }
  
  .member-status-cell {
    padding-right: 2rem;
    min-width: 100px;
  }
  
  .member-actions-cell {
    text-align: right;
    padding-right: 1.5rem;
    min-width: 120px;
  }
  
  .remove-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    background-color: white;
    transition: all 0.2s;
    min-width: 110px;
    gap: 0.5rem;
    white-space: nowrap;
  }
  
  .remove-button img {
    flex-shrink: 0;
  }
  
  .remove-button:hover {
    background-color: #f3f4f6;
  }
  
  @media (max-width: 768px) {
    .member-info-cell {
      min-width: 250px;
    }
  
    .member-role-cell,
    .member-status-cell {
      padding-right: 1rem;
    }
  
    .member-actions-cell {
      min-width: 100px;
    }
  
    .remove-button {
      min-width: 100px;
      padding: 0.5rem 0.75rem;
    }
  }