.app-link-button {
  min-width: 110px;
  white-space: nowrap;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .app-link-button {
    min-width: auto;
    padding-left: 12px;
    padding-right: 12px;
  }
}