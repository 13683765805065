
/* General styles */

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.page-title {
  font-family: 'Manrope', sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 0.25rem;
}

/* Sidebar */

.sidebar-section-heading {
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.08em;
  color: #68867D;
  text-transform: uppercase;
}

/* Homepage */

.home-page-stat-selector {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
}

.home-page-stat-selector-active {
  color: theme('colors.pink');
  border-bottom: 2px solid theme('colors.pink');
  font-weight: 700;
}

.home-page-stat-selector-inactive {
    color: theme('colors.dark-green');
    border-bottom: 2px solid transparent;
    font-weight: 500;
}

.home-page-section-title {
  font-family: 'Manrope', sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  color: theme('colors.dark-green');
  margin-bottom: 0.75rem;
}

.home-stat-title {
  font-size: 0.75rem;
  color: theme('colors.fortify-green');
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.home-stat-number {
  @apply text-[33px] text-dark-green font-extrabold tracking-[-0.4%] my-1;
}

.home-stat-caption {
  font-size: 0.875rem;
  font-weight: 400;
  color: #68867d;
}

/* Application Side Frame */
.application-frame-section-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1rem;
  color: theme('colors.dark-green');
}

.application-frame-body {
  font-size: 16px;
  color: theme('colors.dark-green');
}

@media (max-width: 1200px) {
  .home-page-stat-selector {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}