.dashboard-container {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
  
  .dashboard-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    min-height: 100%;
  }
  
  .home-stats-container {
    flex: 2;
    margin-right: 100px;
  }
  
  .action-items-container {
    flex: 1;
    max-height: calc(100vh - 250px); /* Adjust 200px as needed to account for other elements */
    overflow-y: auto;
  }

  @media (max-width: 1400px) {
    .home-stats-container {
        margin-right: 66px;
    }
  }

  @media (max-width: 1300px) {
    .home-stats-container {
        margin-right: 33px;
    }
  }
  
  @media (max-width: 1200px) {
    .dashboard-content {
      flex-direction: column-reverse;
    }
  
    .home-stats-container {
      margin-right: 0;
      margin-top: 40px;
      width: 100%;
    }
  
    .action-items-container {
      width: 100%;
      padding-bottom: 30px;
      border-bottom: 2px solid #e1e7e5;
      max-height: none;
      overflow-y: visible;
    }
  }