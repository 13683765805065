.loan-table-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1;
}

.loan-table-container {
  overflow-x: auto;
  overflow-y: auto;
  border-radius: 14px 14px 0 0;
  background-color: white;
}

.loan-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.loan-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f3f4f6;
}

.loan-table th {
  font-weight: 500;
  font-size: 15px;

  padding: 0.75rem 1rem;
  text-align: left;
  white-space: nowrap;
  border-bottom: 0.6px solid rgba(75, 82, 106, 0.13);
  color: theme('colors.dark-green');
}

.loan-table td {
  padding: 0.75rem 1rem;
  text-align: left;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 400;
  color: #68867d;
  border-bottom: 1px solid rgba(75, 82, 106, 0.13);
  vertical-align: middle;
}
