.sidebar {
  min-width: 260px;
  width: 260px;
  border-right: 1px solid #e5e7eb;
  display: flex;
  flex-direction: column;
  height: 100vh;
  transition: all 0.3s ease;
  position: relative;
}

.sidebar-collapsed {
  min-width: 110px !important;
  width: 110px !important;
  background-color: white !important;
}

.sidebar-collapsed .sidebar-link-active,
.sidebar-collapsed .sidebar-link-inactive {
  background-color: transparent;
}

.sidebar-collapsed .sidebar-link-active {
  position: relative;
}

.sidebar-collapsed .sidebar-link-active::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background-color: #F0F0F0;
  border-radius: 6px;
  z-index: 0;
}

.sidebar-collapsed .sidebar-link-active img {
  position: relative;
  z-index: 1;
}

.sidebar-collapsed .sidebar-link-active::before {
  display: none;
}

.sidebar-collapsed .page-icon {
  margin-right: 0;
}

.sidebar-collapsed .sidebar-link {
  display: flex;
  justify-content: center;
  padding: 0.55rem 0;
  position: relative;
}

.sidebar-collapsed .sidebar-link span {
  display: none;
}

.sidebar-collapsed .user-section {
  border-top-color: rgba(255, 255, 255, 0.2);
  margin: 0rem 0.5rem 1rem;
}

.sidebar-collapsed .user-info,
.sidebar-collapsed .school-name,
.sidebar-collapsed .school-selector-content span {
  display: none;
}

.sidebar-collapsed .school-selector-content {
  justify-content: center;
  padding: 0.5rem;
}

.sidebar-collapsed .user-section-content {
  justify-content: center;
  margin-top: 1rem;
}

.sidebar-collapsed .school-selector-arrow {
  display: none;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  overflow-y: hidden;
  height: 100vh;
  padding-left: 8rem;
  padding-right: 8rem;
  background-color: #fbfbfd;
}

@media (max-width: 1600px) {
  .main-content {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (max-width: 1400px) {
  .main-content {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media (max-width: 1300px) {
  .main-content {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
}

@media (max-width: 1050px) {
  .main-content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

#data {
  margin-top: 2.5rem;
  margin-bottom: 6rem;
}

@media (max-height: 950px) {
  #data {
    margin-top: 1.5rem;
    margin-bottom: 4rem;
  }
}

@media (max-height: 800px) {
  #data {
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
}
