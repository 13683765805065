.action-items {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.action-items-content {
  flex-grow: 1;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.action-items-content::-webkit-scrollbar {
  display: none;
}

.action-items-content:hover {
  -ms-overflow-style: auto;
  scrollbar-width: auto;
}

.action-items-content:hover::-webkit-scrollbar {
  display: block;
  width: 8px;
}

.action-items-content:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.action-items-list {
  max-height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.action-items-list::-webkit-scrollbar {
  display: none;
}

.action-items-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.action-items-empty-message {
  text-align: center;
  color: #68867d;
}

.certification-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 0.7px solid #e1e7e5;
  border-radius: 0.5rem;
}

.certification-action-info {
  flex-grow: 1;
}

.certification-action-buttons {
  display: flex;
  gap: 0.5rem;
}

.certification-action-content {
  display: flex;
  flex-direction: column;
}

.certification-action-text {
  margin-bottom: 1rem;
}

.certification-action-buttons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

@media (max-width: 1200px) {
  .certification-action-content {
    flex-direction: row;
    align-items: flex-start;
  }

  .certification-action-text {
    flex-grow: 1;
    margin-right: 2rem;
    margin-bottom: 0;
  }

  .certification-action-buttons {
    flex-direction: row;
    width: 250px;
  }

  .certification-action-buttons button {
    width: 50%;
    min-width: 125px;
  }
}

@media (max-width: 1200px) {
  .action-items {
    max-height: 400px;
    overflow-y: auto;
  }
}