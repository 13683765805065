.stat-row {
  display: flex;
  justify-content: space-between;
}

.stat {
  flex: 1;
  padding: 0 0.5rem;
}

.stat:first-child {
  padding-left: 0;
}

.stat:last-child {
  padding-right: 0;
}