.pill-button {
  display: inline-flex;
  align-items: center;
  height: 44px;
  border-radius: 8px;
  padding: 0 16px;
  margin-right: 10px;
  font-size: 16px;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
}

.pill-button.active {
  background-color: #EED9F6;
  border: 1px solid theme('colors.pink');
}

.pill-button:not(.active) {
  background-color: white;
  border: 0.7px solid rgba(104, 134, 125, 0.2);
}

.pill-button:not(.active):hover {
  background-color: rgba(240, 240, 240, 0.8);
}

.pill-text {
  margin-right: 12px;
}

.pill-button.active .pill-text {
  color: theme('colors.pink');
  font-weight: 600;
}

.pill-button:not(.active) .pill-text {
  color: theme('colors.dark-green');
}

.pill-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 13.5px;
  font-weight: 600;
}

.pill-buttons-container {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  flex-grow: 1;
  margin-right: 1rem;
}

.pill-buttons-container::-webkit-scrollbar {
  display: none;
}

.export-csv-button {
  min-width: 120px;
  height: 30px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
