.application-table-container {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 14px 14px 0 0;
  height: 100%;
}

.application-table-container.few-rows {
  height: auto;
}

.application-table-container.many-rows {
  flex-grow: 1;
  height: 500px;
}

.application-table-container.empty {
  height: 200px;
}

.application-table-wrapper {
  overflow-y: auto;
  flex-grow: 1;
}

.application-table-empty-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #68867d;
  font-size: 14px;
}

.application-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.application-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f3f4f6;
}

.application-table th {
  font-weight: 500;
  font-size: 15px;
  white-space: nowrap;

  border-bottom: 0.6px solid rgba(75, 82, 106, 0.13);
  color: theme('colors.dark-green');

  padding: 0.75rem 1rem;
  text-align: left;
}

.application-table td {
  white-space: nowrap;

  font-size: 14px;
  font-weight: 400;
  color: #68867d;
  border-bottom: 1px solid rgba(75, 82, 106, 0.13);

  padding: 0.75rem 1rem;
  text-align: left;
}

.application-table td:last-child,
.application-table th:last-child {
  text-align: right;
}
