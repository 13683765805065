.page-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
}

@media (max-width: 800px) {
    .page-title {
        font-size: 20px;
    }
}

.loan-details-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

.loan-details-header {
    flex-shrink: 0;
}

.loan-details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    row-gap: 32px;
    column-gap: 48px;
}

@media (max-width: 1535px) {
    .loan-details-grid {
        column-gap: 32px;
    }
}

@media (max-width: 1023px) {
    .loan-details-grid {
        column-gap: 18px;
    }
}

@media (max-width: 800px) {
    .loan-details-grid {
        grid-template-columns: 1fr;
    }
}

.loan-details-tabs {
    display: flex;
    overflow-x: auto;
    border-bottom: 1px solid #e1e7e5;
    flex-shrink: 0;
}

.loan-details-tab {
    padding: 12px 16px;
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;
    border: none;
    background-color: transparent;
}

.loan-details-tab-active {
    color: theme('colors.pink');
    font-weight: bold;
    border-bottom: 2px solid theme('colors.pink');
}

.loan-details-tab-inactive {
    color: #4a5568;
}

.loan-details-tab-inactive:hover {
    background-color: #f7fafc;
}

.loan-details-content {
    flex: 1;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.loan-details-content-default {
    padding: 48px;
}

@media (max-width: 1023px) {
    .loan-details-content-default {
        padding: 36px;
    }
}

.loan-details-content-small {
    padding: 24px;
}

.circle-status-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 3rem 0 3rem;
}

@media (min-width: 1024px) {
    .circle-status-container {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
}

@media (min-width: 1280px) {
    .circle-status-container {
        width: 90%;
    }
}

.circle-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 18%;
}

.circle-status-indicator {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 20px;
}

.circle-status-label {
    margin-top: 16px;
    text-align: center;
    font-size: 14px;
    word-wrap: break-word;
    max-width: 100%;
}

@media (max-width: 1000px) {
    .circle-status-indicator {
        width: 48px;
        height: 48px;
        font-size: 18px;
    }

    .circle-status-label {
        font-size: 13px;
    }
}

.info-item {
    margin-bottom: 8px;
}

.info-item-label {
    font-weight: 600;
}

.transactions-tab {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.transactions-table-container {
    flex: 1;
    overflow-y: auto;
}

.transactions-empty-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.notes-table-container {
    overflow-x: auto;
    width: 100%;
}

.notes-tab {
    display: flex;
    flex-direction: column;
    height: 100%;
}