.bug-reports-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
}

.bug-reports-subtitle {
  font-size: 1rem;
  color: #68867d;
  margin-bottom: 2rem;
}

.bug-reports-form {
  background-color: white;
  border: 0.7px solid #e1e7e5;
  border-radius: 0.5rem;
  padding: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #68867d;
  margin-bottom: 0.5rem;
}

.form-select,
.form-input,
.form-textarea {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #e1e7e5;
  border-radius: 0.25rem;
  color: #68867d;
}

.form-select:focus,
.form-input:focus,
.form-textarea:focus {
  outline: none;
  border-color: #00684a;
  box-shadow: 0 0 0 2px rgba(0, 104, 74, 0.1);
}

.submit-button {
  background-color: #00684a;
  color: white;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.submit-button:hover {
  background-color: #005a3e;
}

.submit-button:disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
}

.error-message {
  color: #DA4244;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.success-message {
  color: theme('colors.dark-green');
  font-size: 1rem;
  margin-top: 1rem;
}

.line-divider {
  border: none;
  border-top: 1px solid #e1e7e5;
}
